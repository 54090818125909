#create_invite {
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
}

#create_invite > #submit {
  margin-top: 5px;
}

.instructor-profile-items {
  display: grid;
  grid-column: content;

  padding: 25px;
  margin-top: 30px;

  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr auto;

  border: solid 0.5px lightgrey;

  grid-row-gap: 35px;
  grid-column-gap: 10px;
}

.instructor-profile-text {
  display: grid;

  grid-template-columns: 50% 50%;
  margin-bottom: 10px;
  padding: 5px 25px 0 15px;

  border: solid 0.5px lightgrey;
}

.instructor-profile-text > div {
  margin: 2px 0 7px 0;
}

.instructor-profile-texts {
  display: grid;

  grid-template-columns: auto;
  margin-bottom: 15px;
}

.invites {
  grid-template-columns: auto;
  grid-template-rows: 25px 35px;
  overflow: visible !important;
}

.invite {
  margin-top: 15px;
  border: solid 0.5px lightgrey;
  padding: 5px 25px 0 15px;
  overflow-x: auto;
}

.invite > .label {
  margin-top: 5px;
}

.invite > .value {
  font-size: larger;
}

.invites > .profile_item_value > .list {
  display: grid;

  grid-template-columns: auto;
}

#invite_msg {
  background-color: #eeeeee;
  border-radius: 2px;
  padding: 5px;
}

#invite_msg a {
  color: #00478f;
  text-decoration: none 
}

#invite_msg a:hover {
  text-decoration: underline;
}

.text_label a {
  color: #00478f;
  text-decoration: none
}

.text_label a:hover {
  text-decoration: underline; 
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .instructor-profile-items {
    display: flex;
    flex-direction: column;

    margin-top: 15px;
    grid-template-columns: 50% 50%;
  }
}
